import { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import copy from "copy-to-clipboard";
import ReactDOMServer from 'react-dom/server';
import {logos} from "../misc/Logos"

export default function CopySignature() {
  const name = useSelector((state) => state.info.name);
  const title = useSelector((state) => state.info.title);
  const phone = useSelector((state) => state.info.phone);
  const company = useSelector((state) => state.info.company);
  const email = useSelector((state) => state.info.email);
  const logo = useSelector((state) => state.info.logo);

  const [signatureCopied, setSignatureCopied] = useState(false);
 
  const formatPhoneNumber = (number) => {
    if(!number) return
    
    let telNumber = number;
    // replace 00 with + in start of number
    if(telNumber.startsWith("00")){
      telNumber = telNumber.replace("00", "+");
    }
    // remove "+47
    telNumber = telNumber.replace("+47", "");
    // remove "+ 47
    telNumber = telNumber.replace("+ 47", "");
    // trim whitespace
    telNumber = telNumber.trim();
    // remove all spaces
    telNumber = telNumber.replace(/ /g, "");
    // add +47 if no other local is specified
    if(!telNumber.startsWith("+")){
      telNumber = "+47"+telNumber
    }
    return telNumber
  }


  const signaturHtml = ReactDOMServer.renderToStaticMarkup(
    <div style = {{fontFamily:"Arial", fontSize:"13px", color:"black"}}>
      {name && (<>{name}<br /></>)}
      {title && (<>{title}<br /></>)}
      {company && (<>{company}<br /></>)}
      {phone && (<><a style={{color:"black"}} href={`tel:${formatPhoneNumber(phone)}`}>{phone}</a><br /></>)}
      {email && (<><a style={{color:"black"}} href={`mailto:${email}`}>{email}</a><br /></>)}
      {logo && (<><br />
        {/* eslint-disable */}
        <img
          width={logos[logo].width}
          height={logos[logo].height}
          style={{width:`${logos[logo].width}px`, height:`${logos[logo].height}px`}}
          src={logos[logo].img}
        />
        {/* eslint-enable */}
      </>)}
    </div>
  )
  const signaturPlain = ReactDOMServer.renderToStaticMarkup(
    <>
      {name && (<>{name}</>)}
      {title && (<>{"\n"}{title}</>)}
      {company && (<>{"\n"}{company}</>)}
      {phone && (<>{"\n"}{phone}</>)}
      {email && (<>{"\n"}{email}</>)}
    </>
  )

  const copySignatureClass = classNames("copy-signature", {
    "copy-signature--copied": signatureCopied,
  });

  const onSignatureCopy = () => {
    copy(signaturPlain, {
      format: "text/plain",
      onCopy: (clipboardData) => {
        clipboardData.setData("text/html", signaturHtml);
        setSignatureCopied(true);
        setTimeout(function () {
          setSignatureCopied(false);
        }, 2000);
      },
    });
  };

  return (
    <>
      <div onClick={onSignatureCopy} className={copySignatureClass}>
        {signatureCopied ? "Kopiert 🎉" : "Kopier signatur"}
      </div>
    </>
  );
}
