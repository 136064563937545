import { useSelector } from "react-redux";
import { InputsHasData } from "../misc/InputsHasData";
import { Step, CopySignature, MailClient } from "./";
import {
  outlookMac as outlookMacIcon,
  outlookWindows as outlookWindowsIcon,
  outlookIos as outlookIosIcon,
  outlookAndroid as outlookAndroidIcon,
  outlookWeb as outlookWebIcon,
} from "../misc/Icons";

export default function Instructions() {
  const mailClientId = useSelector((state) => state.mailClient.id);
  const instructionMacOutlook = (
    <>
      {!InputsHasData() ? (
        <Step number="1">
          <p>Vennligst start med å fylle inn detaljene dine over.</p>
        </Step>
      ) : (
        <>
          <Step number="1">
            <CopySignature />
          </Step>
          <Step number="2">
            <p>Åpne Outlook</p>
          </Step>
          <Step number="3">
            <p>I menylinjen, trykk på "Outlook" og velg "Innstillinger"</p>
            <img src="/instructions/outlook-mac-1.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="4">
            <p>Trykk på "Signaturer"</p>
            <img src="/instructions/outlook-mac-2.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="5">
            <p>Trykk på "+" for å lage en ny signatur</p>
            <img src="/instructions/outlook-mac-3.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="6">
            <p>
              Gi et navn på signaturen, slett evt. innhold som er i signaturen
              fra før og lim inn signaturen som du kopierte i første steg
            </p>
            <img src="/instructions/outlook-mac-4.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="7">
            <p>Trykk på "Lagre-ikonet", og lukk vinduet</p>
            <img src="/instructions/outlook-mac-5.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="8">
            <p>
              Du kan nå velge å bruke denne signaturen når du skriver en ny
              melding og/eller svarer på meldinger
            </p>
            <img src="/instructions/outlook-mac-6.png" alt="skjermbilde" loading="lazy"/>
          </Step>
        </>
      )}
    </>
  );
  const instructionIosOutlook = (
    <>
      {!InputsHasData() ? (
        <Step number="1">
          <p>Vennligst start med å fylle inn detaljene dine over.</p>
        </Step>
      ) : (
        <>
          <Step number="1">
            <CopySignature />
          </Step>
          <Step number="2">
            <p>Åpne Outlook</p>
          </Step>
          <Step number="3">
            <p>Trykk på profilbildet øverst i venstre hjørne</p>
            <img src="/instructions/outlook-ios-1.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="4">
            <p>Trykk på "Innstillinger-ikonet" nederst i venstre hjørne</p>
            <img src="/instructions/outlook-ios-2.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="5">
            <p>Trykk på "Signatur"</p>
            <img src="/instructions/outlook-ios-3.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="6">
            <p>
              Slett evt. innhold som er i signaturen fra før og lim inn
              signaturen som du kopierte i første steg
            </p>
            <img src="/instructions/outlook-ios-4.png" alt="skjermbilde" loading="lazy"/>
          </Step>
        </>
      )}
    </>
  );
  const instructionAndroidOutlook = (
    <>
      {!InputsHasData() ? (
        <Step number="1">
          <p>Vennligst start med å fylle inn detaljene dine over.</p>
        </Step>
      ) : (
        <>
          <Step number="1">
            <CopySignature />
          </Step>
          <Step number="2">
            <p>Åpne Outlook</p>
          </Step>
          <Step number="3">
            <p>Trykk på profilbildet øverst i venstre hjørne</p>
            <img src="/instructions/outlook-android-1.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="4">
            <p>Trykk på "Innstillinger-ikonet" nederst i venstre hjørne</p>
            <img src="/instructions/outlook-android-2.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="5">
            <p>Trykk på "Signatur"</p>
            <img src="/instructions/outlook-android-3.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="6">
            <p>
              Slett evt. innhold som er i signaturen fra før og lim inn
              signaturen som du kopierte i første steg
            </p>
            <img src="/instructions/outlook-android-4.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="7">
            <p>Trykk på "fullfør-ikonet"</p>
            <img src="/instructions/outlook-android-5.png" alt="skjermbilde" loading="lazy"/>
          </Step>
        </>
      )}
    </>
  );
  const instructionWebOutlook = (
    <>
      {!InputsHasData() ? (
        <Step number="1">
          <p>Vennligst start med å fylle inn detaljene dine over.</p>
        </Step>
      ) : (
        <>
          <Step number="1">
            <CopySignature />
          </Step>
          <Step number="2">
            <p>
              Logg på{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://outlook.office.com/"
              >
                outlook.office.com
              </a>
            </p>
          </Step>
          <Step number="3">
            <p>Trykk på "Innstillinger-ikonet" på menylinjen</p>
            <img src="/instructions/outlook-web-1.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="4">
            <p>Trykk på "Vis alle Outlook-innstillinger"</p>
            <img src="/instructions/outlook-web-2.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="5">
            <p>Trykk på "E-post" deretter "Skriv og svar"</p>
            <img src="/instructions/outlook-web-3.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="6">
            <p>
              Slett evt. innhold som er i signaturen fra før og lim inn
              signaturen som du kopierte i første steg
            </p>
            <img src="/instructions/outlook-web-4.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="7">
            <p>
              Du kan nå velge å bruke denne signaturen når du skriver en ny
              melding og/eller svarer på meldinger
            </p>
            <img src="/instructions/outlook-web-5.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="8">
            <p>Trykk på "Lagre"</p>
            <img src="/instructions/outlook-web-6.png" alt="skjermbilde" loading="lazy"/>
          </Step>
        </>
      )}
    </>
  );
  const instructionWindowsOutlook = (
    <>
      {!InputsHasData() ? (
        <Step number="1">
          <p>Vennligst start med å fylle inn detaljene dine over.</p>
        </Step>
      ) : (
        <>
          <Step number="1">
            <CopySignature />
          </Step>
          <Step number="2">
            <p>Åpne Outlook</p>
          </Step>
          <Step number="3">
            <p>I menylinjen under "Hjem", trykk på "Ny e-post"</p>
            <img src="/instructions/outlook-windows-1.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="4">
            <p>I menylinjen under "Melding", trykk på "Signatur" deretter "Signaturer..."</p>
            <img src="/instructions/outlook-windows-2.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="5">
            <p>Trykk på "Ny" og gi et navn på signaturen for å lage en ny signatur</p>
            <img src="/instructions/outlook-windows-3.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="6">
            <p>
              Slett evt. innhold som er i signaturen fra før og lim inn signaturen som du kopierte i første steg
            </p>
            <img src="/instructions/outlook-windows-4.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="7">
            <p>
              Du kan nå velge å bruke denne signaturen når du skriver en ny melding og/eller svarer på meldinger
            </p>
            <img src="/instructions/outlook-windows-5.png" alt="skjermbilde" loading="lazy"/>
          </Step>
          <Step number="8">
            <p>Trykk på "OK"</p>
            <img src="/instructions/outlook-windows-6.png" alt="skjermbilde" loading="lazy"/>
          </Step>
        </>
      )}
    </>
  );

  const mailClientInstructions = (
    <>
      {mailClientId === "outlookMac" && instructionMacOutlook}
      {mailClientId === "outlookIos" && instructionIosOutlook}
      {mailClientId === "outlookAndroid" && instructionAndroidOutlook}
      {mailClientId === "outlookWeb" && instructionWebOutlook}
      {mailClientId === "outlookWindows" && instructionWindowsOutlook}
    </>
  );

  return (
    <div className="instructions">
      <h2>Instruksjoner</h2>
      <h3 className="mail-clients__header">Outlook</h3>
      <div className="mail-clients">
        <MailClient
          name="Outlook"
          plattform="Mac"
          id="outlookMac"
          icon={outlookMacIcon}
        />
        <MailClient
          name="Outlook"
          plattform="Windows"
          id="outlookWindows"
          icon={outlookWindowsIcon}
        />
        <MailClient
          name="Outlook"
          plattform="iPhone/iPad"
          id="outlookIos"
          icon={outlookIosIcon}
        />
        <MailClient
          name="Outlook"
          plattform="Android"
          id="outlookAndroid"
          icon={outlookAndroidIcon}
        />
        <MailClient
          name="Outlook"
          plattform="Web"
          id="outlookWeb"
          icon={outlookWebIcon}
        />
      </div>

      {mailClientId && (
        <div className="instructions__content">{mailClientInstructions}</div>
      )}
    </div>
  );
}
