import { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import {
  lightMode as lightModeIcon,
  darkMode as darkModeIcon,
} from "../misc/Icons";
import { CopySignature, DownloadLogo } from "./";
import { InputsHasData } from "../misc/InputsHasData";
import { logos } from "../misc/Logos";

export default function Preview() {
  const name = useSelector((state) => state.info.name);
  const title = useSelector((state) => state.info.title);
  const phone = useSelector((state) => state.info.phone);
  const company = useSelector((state) => state.info.company);
  const email = useSelector((state) => state.info.email);
  const logo = useSelector((state) => state.info.logo);

  const [darkmode, setDarkmode] = useState(false);

  const previewContainerClass = classNames("preview-container", {
    "preview-container--dark": darkmode,
  });

  return (
    <div className="preview">
      <h2>Forhåndsvisning</h2>
      <div className={previewContainerClass}>
        <div className="color-mode" onClick={() => setDarkmode(!darkmode)}>
          {darkmode ? lightModeIcon : darkModeIcon}
        </div>
        <div className="preview-content">
          {InputsHasData() ? (
            <>
              {name && (
                <>
                  {name}
                  <br />
                </>
              )}
              {title && (
                <>
                  {title}
                  <br />
                </>
              )}
              {company && (
                <>
                  {company}
                  <br />
                </>
              )}
              {phone && (
                <>
                  {/* eslint-disable-next-line */}
                  <a style={{ color: "inherit" }} href="">
                    {phone}
                  </a>
                  <br />
                </>
              )}
              {email && (
                <>
                 {/* eslint-disable-next-line */}
                  <a style={{ color: "inherit" }} href="">
                    {email}
                  </a>
         
                  <br />
                </>
              )}
              {logo && (
                <>
                  <br />
                  <img
                    width={logos[logo].width}
                    height={logos[logo].height}
                    src={logos[logo].img}
                    alt=""
                  />
                </>
              )}
            </>
          ) : (
            <p style={{ margin: "30px 0", fontSize: "1rem" }}>
              Vennligst fyll inn detaljene dine.
            </p>
          )}
        </div>
      </div>
      {InputsHasData() && (
        <>
          <CopySignature />
          <DownloadLogo />
        </>
      )}
    </div>
  );
}
