export const lightMode = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M12 9c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3m0-2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zM2 13h2c.55 0 1-.45 1-1s-.45-1-1-1H2c-.55 0-1 .45-1 1s.45 1 1 1zm18 0h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1zM11 2v2c0 .55.45 1 1 1s1-.45 1-1V2c0-.55-.45-1-1-1s-1 .45-1 1zm0 18v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zM5.99 4.58a.996.996 0 00-1.41 0 .996.996 0 000 1.41l1.06 1.06c.39.39 1.03.39 1.41 0s.39-1.03 0-1.41L5.99 4.58zm12.37 12.37a.996.996 0 00-1.41 0 .996.996 0 000 1.41l1.06 1.06c.39.39 1.03.39 1.41 0a.996.996 0 000-1.41l-1.06-1.06zm1.06-10.96a.996.996 0 000-1.41.996.996 0 00-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06zM7.05 18.36a.996.996 0 000-1.41.996.996 0 00-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06z"></path>
  </svg>
);
export const darkMode = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M9.37 5.51A7.35 7.35 0 009.1 7.5c0 4.08 3.32 7.4 7.4 7.4.68 0 1.35-.09 1.99-.27A7.014 7.014 0 0112 19c-3.86 0-7-3.14-7-7 0-2.93 1.81-5.45 4.37-6.49zM12 3a9 9 0 109 9c0-.46-.04-.92-.1-1.36a5.389 5.389 0 01-4.4 2.26 5.403 5.403 0 01-3.14-9.8c-.44-.06-.9-.1-1.36-.1z"></path>
  </svg>
);
export const outlookIos = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M71.05 5H29a5.09 5.09 0 00-5.08 5.08v79.84A5.09 5.09 0 0029 95h42.1a5.09 5.09 0 005.08-5.08V10.08A5.09 5.09 0 0071.05 5zm-14.2 2.9l-.73 2.91H43.88l-.73-2.91zm16.38 82a2.19 2.19 0 01-2.18 2.18H29a2.19 2.19 0 01-2.18-2.18V10.08A2.19 2.19 0 0129 7.9h11.16l1.17 4.71a1.46 1.46 0 001.41 1.1h14.52a1.46 1.46 0 001.41-1.1l1.17-4.71h11.21a2.19 2.19 0 012.18 2.18z"></path>
    <path d="M45 68.29c-5.45 0-10-11.15-10-16.8 0-9.25 6.93-11.27 9.61-11.27a11 11 0 013.62.89 8.3 8.3 0 002.07.59 6.14 6.14 0 001.49-.48 11.94 11.94 0 014.47-1.08A9.18 9.18 0 0164 44l.56.84-.81.61a6.76 6.76 0 00-3.25 5.59 6.32 6.32 0 003.5 5.85c.51.3 1 .62 1 1.3 0 .45-3.57 10-8.75 10a7.14 7.14 0 01-3-.71 6 6 0 00-2.63-.64 6.89 6.89 0 00-2.08.57 10.24 10.24 0 01-3.61.84zm11.81-36.58c.14 4.87-3.34 8.24-6.82 8-.57-3.85 3.48-8 6.82-8z"></path>
  </svg>
);
export const outlookAndroid = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M71.05 5H29a5.09 5.09 0 00-5.08 5.08v79.84A5.09 5.09 0 0029 95h42.1a5.09 5.09 0 005.08-5.08V10.08A5.09 5.09 0 0071.05 5zm-14.2 2.9l-.73 2.91H43.88l-.73-2.91zm16.38 82a2.19 2.19 0 01-2.18 2.18H29a2.19 2.19 0 01-2.18-2.18V10.08A2.19 2.19 0 0129 7.9h11.16l1.17 4.71a1.46 1.46 0 001.41 1.1h14.52a1.46 1.46 0 001.41-1.1l1.17-4.71h11.21a2.19 2.19 0 012.18 2.18z"></path>
    <path d="M37.19 43.76a2.1 2.1 0 00-1.55.64 2.05 2.05 0 00-.64 1.53v9.16a2.19 2.19 0 003.74 1.56 2.15 2.15 0 00.63-1.56v-9.16a2.19 2.19 0 00-2.18-2.17zm17.65-8.23l1.51-2.79a.28.28 0 00-.11-.42.28.28 0 00-.42.12l-1.54 2.82a10.63 10.63 0 00-8.56 0l-1.54-2.82a.28.28 0 00-.42-.12.28.28 0 00-.11.42l1.51 2.79a9.34 9.34 0 00-3.66 3.27 8.26 8.26 0 00-1.36 4.6h19.7a8.26 8.26 0 00-1.36-4.6 9.35 9.35 0 00-3.64-3.27zm-8.75 4.06a.78.78 0 01-.59.25.75.75 0 01-.57-.25.83.83 0 010-1.17.78.78 0 01.57-.24.81.81 0 01.59.24.81.81 0 010 1.17zm9 0a.75.75 0 01-.57.25.78.78 0 01-.59-.25.83.83 0 01.59-1.41.78.78 0 01.57.24.83.83 0 010 1.17zM40.22 58.35a2.34 2.34 0 002.34 2.35h1.58v4.83a2.19 2.19 0 104.37 0V60.7h2.94v4.83a2.19 2.19 0 002.19 2.2 2.16 2.16 0 001.56-.64 2.12 2.12 0 00.64-1.56V60.7h1.6a2.23 2.23 0 001.66-.7 2.26 2.26 0 00.68-1.66V44.16H40.22zm22.59-14.59a2.12 2.12 0 00-1.54.63 2.08 2.08 0 00-.64 1.54v9.16a2.16 2.16 0 00.64 1.56A2.19 2.19 0 0065 55.09v-9.16a2 2 0 00-.64-1.54 2.13 2.13 0 00-1.55-.63z"></path>
  </svg>
);
export const outlookWindows = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M85.48 13.87h-71A4.52 4.52 0 0010 18.39v52.9a4.53 4.53 0 004.52 4.52h24.54l-1.55 3.87h-6.86a1.3 1.3 0 00-1.3 1.32v3.87a1.3 1.3 0 001.3 1.29h38.7a1.3 1.3 0 001.3-1.29V81a1.3 1.3 0 00-1.3-1.29h-6.86l-1.55-3.87h24.54A4.53 4.53 0 0090 71.29v-52.9a4.52 4.52 0 00-4.52-4.52zM68.06 82.26v1.29H31.94v-1.29zm-27.77-2.58l1.55-3.87h16.32l1.55 3.87zm47.13-8.39a2 2 0 01-1.94 1.94h-71a2 2 0 01-1.94-1.94v-3.23h74.88zm0-5.81H12.58V18.39a1.94 1.94 0 011.94-1.94h71a1.94 1.94 0 011.94 1.94z"></path>
    <path d="M35 31.12v9.38h13.13V29.48L35 31.12zm15-1.87V40.5h15V27.37l-15 1.88zm0 13.12v11.25l15 1.88V42.37H50zm-15 0v9.38l13.13 1.64V42.37H35z"></path>
  </svg>
);
export const outlookWeb = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M83.33 10H16.67A6.67 6.67 0 0010 16.67v66.66A6.67 6.67 0 0016.67 90h66.66A6.67 6.67 0 0090 83.33V16.67A6.67 6.67 0 0083.33 10zm4 73.33a4 4 0 01-4 4H16.67a4 4 0 01-4-4v-60h74.66v60zm0-62.66H12.67v-4a4 4 0 014-4h66.66a4 4 0 014 4v4z"></path>
    <path d="M19.33 15.33H18A1.34 1.34 0 0018 18h1.33a1.34 1.34 0 100-2.67zm5.84.11a1.19 1.19 0 00-1 0 1.57 1.57 0 00-.44.28 1.34 1.34 0 000 1.89 1.37 1.37 0 00.44.28 1.19 1.19 0 001 0 1.37 1.37 0 00.44-.28 1.34 1.34 0 000-1.89 1.57 1.57 0 00-.44-.28zm5.34-.01a1.34 1.34 0 00-1.46.29 1.83 1.83 0 00-.28.44 1.27 1.27 0 000 1 1.83 1.83 0 00.28.44 1.35 1.35 0 001.9 0 1.83 1.83 0 00.28-.44 1.07 1.07 0 00.1-.5 1.33 1.33 0 00-.82-1.23zm51.49-.1H36.67a1.34 1.34 0 100 2.67H82a1.34 1.34 0 000-2.67zM30.47 73.49a1 1 0 00.2.15 26.54 26.54 0 0038.68 0l.19-.13a2 2 0 00.11-.2 26.5 26.5 0 000-35.93.82.82 0 00-.12-.2l-.19-.18a26.56 26.56 0 00-38.67 0l-.2.15a1.28 1.28 0 00-.11.2 26.5 26.5 0 000 35.93 1.18 1.18 0 00.11.21zm26.8 4.71a24.41 24.41 0 004.54-8.4 24.44 24.44 0 014.71 2.9 24.1 24.1 0 01-9.25 5.5zm11.09-7.45a27.27 27.27 0 00-5.79-3.55A47.39 47.39 0 0064 56.67h10a23.84 23.84 0 01-5.64 14.08zM73.93 54H64a47.9 47.9 0 00-1.4-10.53 27.27 27.27 0 005.79-3.55A23.84 23.84 0 0173.93 54zm-7.41-16a24 24 0 01-4.71 2.9 24.32 24.32 0 00-4.54-8.4 24 24 0 019.25 5.5zm-15.19-6.48c3.27.86 6.16 4.79 8 10.35a28.19 28.19 0 01-8 1.52zm0 14.53a30.8 30.8 0 008.72-1.6A45.87 45.87 0 0161.3 54h-10zm0 10.62h10a45.86 45.86 0 01-1.25 9.54 30.8 30.8 0 00-8.72-1.6zm0 10.61a28.19 28.19 0 018 1.52c-1.82 5.56-4.71 9.49-8 10.35zm-17.85 5.41a23.91 23.91 0 014.71-2.89 24.32 24.32 0 004.54 8.4 24 24 0 01-9.25-5.51zm15.19 6.46c-3.27-.86-6.16-4.79-8-10.35a28.19 28.19 0 018-1.52v11.87zm0-14.54a30.8 30.8 0 00-8.67 1.6 45.86 45.86 0 01-1.25-9.54h10zm0-10.61h-10A45.69 45.69 0 0140 44.46a30.79 30.79 0 008.72 1.59zm0-22.48v11.87a28.54 28.54 0 01-8-1.52c1.84-5.57 4.73-9.5 8-10.35zm-5.94.94a24.46 24.46 0 00-4.54 8.41A24.44 24.44 0 0133.48 38a24 24 0 019.25-5.54zm-11.09 7.45a26.91 26.91 0 005.79 3.56A47.31 47.31 0 0036 54H26a23.87 23.87 0 015.64-14.09zM36 56.67a47.39 47.39 0 001.4 10.53 27.27 27.27 0 00-5.79 3.55 23.87 23.87 0 01-5.57-14.08z"></path>
  </svg>
);
export const outlookMac = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M85.48 13.87h-71A4.52 4.52 0 0010 18.39v52.9a4.53 4.53 0 004.52 4.52h24.54l-1.55 3.87h-6.86a1.3 1.3 0 00-1.3 1.32v3.87a1.3 1.3 0 001.3 1.29h38.7a1.3 1.3 0 001.3-1.29V81a1.3 1.3 0 00-1.3-1.29h-6.86l-1.55-3.87h24.54A4.53 4.53 0 0090 71.29v-52.9a4.52 4.52 0 00-4.52-4.52zM68.06 82.26v1.29H31.94v-1.29zm-27.77-2.58l1.55-3.87h16.32l1.55 3.87zm47.13-8.39a2 2 0 01-1.94 1.94h-71a2 2 0 01-1.94-1.94v-3.23h74.88zm0-5.81H12.58V18.39a1.94 1.94 0 011.94-1.94h71a1.94 1.94 0 011.94 1.94z" />
    <path d="M45 59.26c-5.45 0-10-11.15-10-16.81 0-9.24 6.93-11.27 9.61-11.27a11 11 0 013.62.9 8.3 8.3 0 002.07.59 6.62 6.62 0 001.49-.48 11.94 11.94 0 014.47-1.08A9.17 9.17 0 0164 35l.56.84-.81.61a6.8 6.8 0 00-3.3 5.55A6.32 6.32 0 0064 47.86c.51.3 1 .61 1 1.3 0 .45-3.57 10-8.75 10a7 7 0 01-3-.72 6 6 0 00-2.63-.63 6.84 6.84 0 00-2.08.56 10 10 0 01-3.61.85zM56.81 22.68c.14 4.86-3.34 8.24-6.82 8-.57-3.86 3.48-8 6.82-8z" />
  </svg>
);