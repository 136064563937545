import { useDispatch, useSelector } from "react-redux";
import { setMailClientId } from "../redux/mailClient";
import classNames from "classnames";

export default function MailClient(props) {
  const dispatch = useDispatch();

  const mailClientId = useSelector((state) => state.mailClient.id);


  const onClickMailClient = () => {
    dispatch(setMailClientId(props.id));
  };

  const mailClientClass = classNames("mail-client", {
    "mail-client--active": mailClientId === props.id,
  });

  return (
    <>
      <div className={mailClientClass} onClick={onClickMailClient}>
        <div className="mail-client__icon">
          {props.icon}
        </div>
        <p>
          {props.plattform}
        </p>
      </div>
    </>
  );
}
