import {Inputs, Preview} from './'

export default function Generator() {

  return (
    <div className="generator">
      <Inputs />
      <Preview />
    </div>
  );
}
