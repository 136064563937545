
export default function Step(props) {
 
  return (
    <>
      <div className="step">
        <div className="step__number">
          {props.number}
        </div>
        <div className="step__content">
          {props.children}
        </div>
      </div>
    </>
  );
}
