import { useSelector } from "react-redux";

export const InputsHasData = () => {
  const name = useSelector((state) => state.info.name);
  const title = useSelector((state) => state.info.title);
  const phone = useSelector((state) => state.info.phone);
  const company = useSelector((state) => state.info.company);
  const email = useSelector((state) => state.info.email);
  if(name || title || company || phone || email){
      return true
  }
  else{
      return false
  }
}

