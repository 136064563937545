import './styles/style.css';
import {Generator, Instructions} from './components'

function App() {
  return (
    <div className="app">
      <div className="container">
        <h1>Akademiet e-post signatur</h1>
        <Generator />
        <Instructions />
      </div>
    </div>
  );
}

export default App;
