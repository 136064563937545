import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: null,
  title: null,
  phone: null,
  email: null,
  company: null,
  logo: null,
};

export const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setLogo: (state, action) => {
      state.logo = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setName, setTitle, setPhone, setEmail, setCompany, setLogo } = infoSlice.actions;

export default infoSlice.reducer;
