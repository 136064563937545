import { useSelector } from "react-redux";
import {logos} from "../misc/Logos"

export default function DownloadLogo() {

  const logo = useSelector((state) => state.info.logo);

  return (
    <>
      {logo && <p style={{fontSize:"16px", margin:"30px 0"}}>Ble ikke logen limt inn riktig i e-post klienten? Last ned logoen <a href={`/logo/${logos?.[logo]?.download}`} download>for seg selv her</a>.</p>}
    </>
  );
}
